<template>
  <v-card class="pa-5" elevation="0">
    <v-form ref="form" lazy-validation>
      <v-row>
        <v-col cols="12" class="justify-center d-flex">
          <div v-if="form.logo">
            <v-img :src="form.logo" id="logo" @click="onButtonClick" />
          </div>
          <div
            v-else
            id="upload-image"
            class="d-flex black--text justify-center align-center"
            @click="onButtonClick"
          >
            Upload Logo
          </div>
          <input
            v-show="false"
            ref="upload"
            type="file"
            accept="image/*"
            @change="onFileChanged"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.name"
            :rules="[(v) => !!v || 'Name must be fill']"
            name="name"
            label="Name"
            dense
            outlined
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.phone_number"
            :rules="[(v) => !!v || 'Phone Number must be fill']"
            name="phone-number"
            label="Phone Number"
            prepend-inner-icon="mdi-phone"
            dense
            outlined
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-textarea
            v-model="form.address"
            :rules="[(v) => !!v || 'Address must be fill']"
            name="address"
            label="Address"
            prepend-inner-icon="mdi-office-building-marker"
            auto-grow
            rows="1"
            dense
            outlined
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.email"
            :rules="[(v) => !!v || 'Email must be fill']"
            name="email"
            label="Email"
            type="email"
            prepend-inner-icon="mdi-email"
            dense
            outlined
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.youtube"
            name="youtube"
            label="Youtube"
            prepend-inner-icon="mdi-youtube"
            dense
            outlined
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.instagram"
            name="instagram"
            label="Instagram"
            prepend-inner-icon="mdi-instagram"
            dense
            outlined
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.facebook"
            name="facebook"
            label="Facebook"
            prepend-inner-icon="mdi-facebook"
            dense
            outlined
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.latitude"
            :rules="[(v) => !!v || 'Latitude must be fill']"
            name="latitude"
            label="Latitude"
            prepend-inner-icon="mdi-latitude"
            dense
            outlined
            type="number"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.longitude"
            :rules="[(v) => !!v || 'Longitude must be fill']"
            name="longitude"
            label="Longitude"
            prepend-inner-icon="mdi-longitude"
            dense
            outlined
          />
        </v-col>
        <v-col cols="12">
          <div class="title font-weight-bold">About Us</div>
          <quill-editor
            ref="myQuillEditor"
            v-model="form.about_us"
            :options="editorOption"
          />
        </v-col>
        <v-col cols="12">
          <div class="title font-weight-bold">Term and Condition</div>
          <quill-editor
            ref="myQuillEditor"
            v-model="form.term_and_condition"
            :options="editorOption"
          />
        </v-col>
        <v-col cols="12">
          <v-divider />
        </v-col>
        <v-col cols="12" class="d-flex justify-end">
          <v-btn
            :disabled="loading"
            :dark="!loading"
            class="mr-2"
            width="150"
            color="gray"
            depressed
            @click="getData"
          >
            Cancel
          </v-btn>
          <v-btn
            :loading="loading"
            width="150"
            color="#04BF68"
            dark
            depressed
            @click="saveData"
          >
            Save
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-btn
      v-scroll="onScroll"
      v-show="fab"
      fab
      dark
      fixed
      bottom
      right
      style="margin-bottom: 70px"
      color="primary"
      @click="toTop"
    >
      <v-icon>mdi-chevron-up</v-icon>
    </v-btn>
  </v-card>
</template>

<script>
import { getInfo, saveInfo } from "@/api/admin/settings";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { isURL } from "@/utils/validate";
import { uploadFile, removeFile } from "@/api/file";

export default {
  components: {
    quillEditor,
  },
  data() {
    return {
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
            [{ font: [] }],
            [{ align: [] }],
            ["clean"],
            ["link"],
          ],
        },
      },
      loading: false,
      form: {
        about_us: "",
        address: "",
        email: "",
        facebook: "",
        instagram: "",
        latitude: 0,
        logo: "",
        longitude: 0,
        name: "",
        phone_number: "",
        term_and_condition: "",
        youtube: "",
      },
      oldLogo: null,
      fileImage: null,
      fab: false,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 800;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    onButtonClick() {
      this.$refs.upload.click();
    },
    onFileChanged(e) {
      if (e.target.files.length > 0) {
        if (isURL(this.form.logo)) {
          this.oldLogo = this.form.logo;
        }
        const file = e.target.files[0];
        this.fileImage = file;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          this.form.logo = e.target.result;
        };
      }
    },
    snackBar(isSuccess, msg) {
      this.$store.commit("SET_SNACKBAR", {
        value: true,
        text: msg,
        color: isSuccess ? "success" : "error",
      });
    },
    getData() {
      getInfo()
        .then((res) => {
          const { data, code, message } = res.data;
          if (code) this.form = data.email ? data : {};
          else this.snackBar(false, message);
          console.log(this.form);
        })
        .catch((err) => {
          console.error("getData()\n", err);
        });
    },
    async saveData() {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true;

          // Upload image
          if (this.fileImage) {
            const form = new FormData();
            form.append("file", this.fileImage);

            const uploadImage = await uploadFile(form);
            if (uploadImage.data.code) {
              this.form.logo = uploadImage.data.data.path;

              // delete old image, if exist
              if (isURL(this.oldLogo)) {
                const delImage = await removeFile({ file: this.oldLogo });
                if (!delImage.data.code)
                  this.snackBar(false, "Failed delete image");
              }
            } else this.snackBar(false, "Failed upload image");
          }

          const response = await saveInfo(this.form);
          const { code, message } = response.data;
          this.snackBar(code === 1, message);
        } catch (error) {
          console.error("saveData()\n", error);
        } finally {
          this.loading = false;
          this.oldLogo = null;
          this.fileImage = null;
          this.getData();
        }
      } else this.$vuetify.goTo(400);
    },
  },
};
</script>

<style lang="scss">
#upload-image {
  border: 2px dotted gray;
  cursor: pointer;
  height: 300px;
  width: 300px;
}
#logo {
  height: 300px;
  width: 300px;
  cursor: pointer;
}
</style>
